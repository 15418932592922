import React from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from './pages/Home.js';
import Job from "./pages/Job.js";
import Jobs from "./pages/Jobs.js";

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/jobs/:customerId" element={<Jobs />} />
        <Route path="/job/:jobId" element={<Job />} />
      </Routes>
    </div>
  );
}
