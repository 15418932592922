const configs = {

    dev: {
        api: {
            host: "http://localhost",
            port: "5001",
        },
    },

    staging: {
        api: {
            host: "http://54.241.24.181",
            port: "5001",
        },
    },

   production: {
	api: {
	    host: "http://52.53.101.246",
	    port: "5001",
	},
    },
};

const config = configs.production;

module.exports = config;
