import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import config from '../config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { visuallyHidden } from '@mui/utils';

const columns = [
    {
        id: 'id',
        label: 'ID',
        isDate: false,
        url: "/job",
    },
    {
        id: 'customer_name',
        label: 'Customer',
        isDate: false,
    },
    {
        id: 'master_path',
        label: 'Master Track',
        isDate: false,
    },
    {
        id: 'status',
        label: 'Status',
        isDate: false,
    },
    {
        id: 'created',
        label: 'Queued',
        isDate: true,
    },
    {
        id: 'started',
        label: 'Started',
        isDate: true,
    },
    {
        id: 'ended',
        label: 'Ended',
        isDate: true,
    }
];

export default function CustomerJobs() {
    let { customerId } = useParams();

    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        if (!customerId)
            return;
        fetch(config.api.host + ":" + config.api.port + "/customer/" + customerId)
            .then((res) => res.json())
            .then((data) => {
                setCustomer(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    if (!customerId || customer) {
        return (
            <div>
                <div style={{
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                }}
                >
                    <img
                        style={{ alignSelf: "center", marginLeft: 16, marginRight: 8, width: 24 }}
                        src={require('../assets/home.png')}
                        alt="HOME"
                        onClick={(e) => window.location.href = "/"}
                    />
                    <p style={{ fontWeight: "bolder", marginLeft: "auto", marginRight: "auto", paddingRight:"40px", paddingTop: "8px", paddingBottom: "8px" }}>
                        {(customer ? customer.name : "All") + " Jobs"}
                    </p>
                </div>
                <CustomerJobsTable
                    customer={customer}
                />
            </div>
        );
    } else {
        return (<div><p>LOADING</p></div>);
    }
}

function CustomerJobsTable({ customer }) {
    const [jobs, setJobs] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(null);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [haveMore, setHaveMore] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [pendingRequest, setPendingRequest] = React.useState(false);

    useEffect(() => {
        if (!haveMore || pendingRequest)
            return;
        const expectedJobsCount = (page + 2) * rowsPerPage;     // current and next
        if (expectedJobsCount <= jobs.length)
            return;
        const requestJobsCount = Math.min(100, expectedJobsCount - jobs.length);
        setPendingRequest(true);
        const url = config.api.host + ":" + config.api.port + "/jobs" +
            (customer ? "?customer_id=" + customer.id + "&" : "?") +
            "start=" + jobs.length +
            "&limit=" + requestJobsCount;
        console.log(url)
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setPendingRequest(false);
                setJobs((jobs) => jobs.concat(data));
                if (data.length < requestJobsCount) {
                    setHaveMore(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [jobs, page, rowsPerPage, pendingRequest])       // we may need to issue multiple fetches, hence dependency on jobs

    const handleSelectAllClick = () => {

    };

    const handleRequestSort = () => {

    };

    const handleClick = (event, jobId) => {
        window.location.href = "/job/" + jobId;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (jobId) => selected.indexOf(jobId) !== -1;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobs.length) : 0;

    const humanDate = (isoDate) => {
        if (isoDate) {
            const options = { year: "numeric", month: "short", day: "numeric" }
            return new Date(isoDate).toLocaleTimeString(undefined, options);
        } else {
            return null;
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <CustomerJobsTableHead
                            customer={customer}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={jobs.length}
                        />
                        <TableBody>
                            {
                                jobs
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((job, index) => {
                                        const isItemSelected = isSelected(job.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, job.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={job.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        key="select"
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                {
                                                    columns.map((column) => (
                                                        (column.id !== "customer_name" || !customer) &&
                                                        <TableCell align="left">
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignContent: "center",
                                                                justifyContent: "flex-start",
                                                            }}
                                                            >
                                                                {
                                                                    column.isDate ? humanDate(job[column.id]) : job[column.id]
                                                                }
                                                                {
                                                                    column.id === "status" && job[column.id] === "failed" &&
                                                                    <img
                                                                        style={{ marginLeft: 6, marginRight: -12, width: 14, height: 14 }}
                                                                        src={require('../assets/failed.png')}
                                                                        alt=""
                                                                        title={job.error}
                                                                    />
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        );
                                    })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 33 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={columns.length + 1} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={jobs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

function CustomerJobsTableHead({ customer, onSelectAllClick, numSelected, rowCount, orderBy, order }) {

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key="select"
                    padding="checkbox"
                >
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {
                    columns.map((column) => (
                        (column.id !== "customer_name" || !customer) &&
                        <TableCell
                            key={column.id}
                            align="center"
                            sortDirection={orderBy === column.id ? order : false}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'asc'}
                            // onClick={createSortHandler(headCell.id)}
                            >
                                {column.label}
                                {orderBy === column.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    );
}
