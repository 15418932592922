import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import config from '../config';
import { PuffLoader } from 'react-spinners';


export default function Job() {
    let { jobId } = useParams();

    const [jobExtended, setJobExtended] = useState(null);

    useEffect(() => {
        fetch(config.api.host + ":" + config.api.port + "/job/" + jobId + "?extended=1")
            .then((res) => res.json())
            .then((data) => {
                setJobExtended(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    if (!jobExtended) {
        return (
            <div style={{ position: "absolute", marginLeft: "-65px", marginTop: "-65px", scale: "2", top: "50%", left: "50%" }}>
                <PuffLoader />
            </div>
        );
    } else {
        return (
            <div>
                <div style={{
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                }}
                >
                    <img
                        style={{ alignSelf: "center", marginLeft: 16, marginRight: 8, width: 24 }}
                        src={require('../assets/home.png')}
                        alt="HOME"
                        onClick={(e) => window.location.href = "/"}
                    />
                    <p style={{ fontWeight: "bolder", marginLeft: "auto", marginRight: "auto", paddingRight: "40px", paddingTop: "8px", paddingBottom: "8px" }}>
                        <div>
                            {"Job #" + jobExtended.job.id}
                        </div>
                    </p>
                </div>
                <div style={{ marginLeft: "24px", textAlign: "left" }}>
                    <JobDetails job={jobExtended.job} />
                </div>
                <div style={{ backgroundColor: "darkgrey", color: "white" }}>
                    <p style={{ fontWeight: "bolder", marginLeft: "auto", marginRight: "auto", paddingRight: "40px", paddingTop: "8px", paddingBottom: "8px" }}>
                        <div>Uploads ({jobExtended.uploads.length})</div>
                    </p>
                </div>
                <div style={{ marginLeft: "24px", textAlign: "left" }}>
                    <Uploads uploads={jobExtended.uploads} />
                </div>
                <div style={{ backgroundColor: "darkgrey", color: "white" }}>
                    <p style={{ fontWeight: "bolder", marginLeft: "auto", marginRight: "auto", paddingRight: "40px", paddingTop: "8px", paddingBottom: "8px" }}>
                        <div>Splits ({jobExtended.splits.length})</div>
                    </p>
                </div>
                <div style={{ marginLeft: "24px", textAlign: "left" }}>
                    <Splits splits={jobExtended.splits} />
                </div>
                <div style={{ backgroundColor: "darkgrey", color: "white" }}>
                    <p style={{ fontWeight: "bolder", marginLeft: "auto", marginRight: "auto", paddingRight: "40px", paddingTop: "8px", paddingBottom: "8px" }}>
                        <div>Downloads ({jobExtended.downloads.length})</div>
                    </p>
                </div>
                <div style={{ marginLeft: "24px", textAlign: "left" }}>
                    <Downloads downloads={jobExtended.downloads} />
                </div>
            </div>
        );
    }
}

const humanDate = (isoDate) => {
    if (isoDate) {
        const options = { year: "numeric", month: "long", day: "numeric", weekday: "long" }
        return new Date(isoDate).toLocaleTimeString(undefined, options);
    } else {
        return null;
    }
};

function JobDetails({ job }) {
    return (
        <div>
            <p>
                <span style={{ color: "grey" }}>Customer: </span>
                <span>{job.customer_name}</span>
            </p>
            <p>
                <span style={{ color: "grey" }}>Master Path: </span>
                <span>{job.master_path}</span>
            </p>
            <p>
                <span style={{ color: "grey" }}>Master Hash: </span>
                <span>{job.source_hash}</span>
            </p>
            <p>
                <span style={{ color: "grey" }}>Status: </span>
                <span>{job.status}</span>
                {job.status === "failed" &&
                    <span> ({job.error})</span>
                }
                <img
                    style={{ marginLeft: 6, marginRight: -12, width: 14, height: 14 }}
                    src={require("../assets/" + job.status + ".png")}
                    alt=""
                    title={job.error}
                />
                {job.status === "failed" &&
                    <button style={{ marginLeft: "24px" }}>RETRY</button>
                }
            </p>
            <p>
                <span style={{ color: "grey" }}>Created: </span>
                <span>{humanDate(job.created)}</span>
            </p>
            <p>
                <span style={{ color: "grey" }}>Started: </span>
                <span>{job.started ? humanDate(job.started) : "---"}</span>
            </p>
            <p>
                <span style={{ color: "grey" }}>Ended: </span>
                <span>{job.ended ? humanDate(job.ended) : "---"}</span>
            </p>
        </div>
    );
}

function Uploads({ uploads }) {
    return (
        <div>
            {
                uploads.map((upload) => (
                    <UploadDetails upload={upload} />
                ))
            }
        </div>
    );
}

function UploadDetails({ upload }) {
    return (
        <div>
            <p>
                <span style={{ color: "grey" }}>ID: </span>
                <span>{upload.id}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Status: </span>
                <span>{upload.status}</span>
                {upload.status === "failed" &&
                    <span> ({upload.error})</span>
                }
                <img
                    style={{ marginLeft: 6, marginRight: -12, width: 14, height: 14 }}
                    src={require("../assets/" + upload.status + ".png")}
                    alt=""
                    title={upload.error}
                />
                {upload.status === "failed" &&
                    <button style={{ marginLeft: "24px" }}>RETRY</button>
                }
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Lalal.AI Key: </span>
                <span>{upload.remote_key}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Started: </span>
                <span>{upload.started ? humanDate(upload.started) : "---"}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Ended: </span>
                <span>{upload.ended ? humanDate(upload.ended) : "---"}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Expires: </span>
                <span>{upload.expires ? humanDate(upload.expires) : "---"}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Size: </span>
                <span>{upload.size} bytes</span>
            </p>
        </div>
    );
}

function Splits({ splits }) {
    return (
        <div>
            {
                splits.map((split) => (
                    <SplitDetails split={split} />
                ))
            }
        </div>
    );
}


function SplitDetails({ split }) {
    return (
        <div>
            <p>
                <span style={{ color: "grey" }}>ID: </span>
                <span>{split.id}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Stem Type: </span>
                <span>{split.stem_type}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Algorithm: </span>
                <span>{split.algorithm}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Status: </span>
                <span>{split.status}</span>
                {split.status === "failed" &&
                    <span> ({split.error})</span>
                }
                <img
                    style={{ marginLeft: 6, marginRight: -12, width: 14, height: 14 }}
                    src={require("../assets/" + split.status + ".png")}
                    alt=""
                    title={split.error}
                />
                {(split.status === "failed" || split.status === "expired" || split.status === "cancelled") &&
                    <button style={{ marginLeft: "24px" }}>RETRY</button>
                }
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Started: </span>
                <span>{split.started ? humanDate(split.started) : "---"}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Ended: </span>
                <span>{split.ended ? humanDate(split.ended) : "---"}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Stem Track: </span>
                <span>{split.stem_path}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Back Track: </span>
                <span>{split.back_path}</span>
            </p>
        </div>
    );
}

function Downloads({ downloads }) {
    return (
        <div>
            {
                downloads.map((download) => (
                    <DownloadDetails download={download} />
                ))
            }
        </div>
    );
}

function DownloadDetails({ download }) {
    return (
        <div>
            <p>
                <span style={{ color: "grey" }}>ID: </span>
                <span>{download.id}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Stem Path: </span>
                <span>{download.stem_path}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Status: </span>
                <span>{download.status}</span>
                {download.status === "failed" &&
                    <span> ({download.error})</span>
                }
                <img
                    style={{ marginLeft: 6, marginRight: -12, width: 14, height: 14 }}
                    src={require("../assets/" + download.status + ".png")}
                    alt=""
                    title={download.error}
                />
                {download.status === "failed" &&
                    <button style={{ marginLeft: "24px" }}>RETRY</button>
                }
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Started: </span>
                <span>{download.started ? humanDate(download.started) : "---"}</span>
            </p>
            <p style={{textIndent: "16px"}}>
                <span style={{ color: "grey" }}>Ended: </span>
                <span>{download.ended ? humanDate(download.ended) : "---"}</span>
            </p>
        </div>
    );
}
